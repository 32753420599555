import React, { useState, useRef } from 'react';
import Barcode from 'react-barcode';
import { PDFDownloadLink, Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

const styles = StyleSheet.create({
  page: { padding: 20 },
  barcodeBlock: { marginBottom: 30, alignItems: 'center', textAlign: 'center' },
  barcodeImage: { width: 200, height: 80 },
  barcodeText: { marginTop: 10 }
});

const BarcodeGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [barcodeList, setBarcodeList] = useState([]);
  const [pdfDoc, setPdfDoc] = useState(null);
  const barcodeRefs = useRef({});

  const handleAddBarcode = () => {
    if (inputText.trim()) {
      setBarcodeList((prev) => [...prev, inputText.trim()]);
      setInputText('');
    }
  };

  const handleRemove = (index) => {
    const newList = [...barcodeList];
    newList.splice(index, 1);
    setBarcodeList(newList);
  };

  const generateBarcodesAsImages = () => {
    return Promise.all(
      barcodeList.map((text) => {
        return new Promise((resolve) => {
          const canvas = document.createElement('canvas');
          const container = document.createElement('div');
          document.body.appendChild(container);

          const barcode = (
            <Barcode
              value={text}
              width={2}
              height={80}
              displayValue={false}
              background="#ffffff"
              renderer="canvas"
            />
          );

          ReactDOM.render(barcode, container, () => {
            const barcodeCanvas = container.querySelector('canvas');
            const imgData = barcodeCanvas.toDataURL('image/png');
            document.body.removeChild(container);
            resolve({ text, img: imgData });
          });
        });
      })
    );
  };

  const handlePreparePDF = async () => {
    const barcodeImages = await generateBarcodesAsImages();

    const pdfContent = (
      <Document>
        <Page size="A4" style={styles.page}>
          {barcodeImages.map(({ text, img }, idx) => (
            <View key={idx} style={styles.barcodeBlock}>
              <Image src={img} style={styles.barcodeImage} />
              <Text style={styles.barcodeText}>{text}</Text>
            </View>
          ))}
        </Page>
      </Document>
    );

    setPdfDoc(pdfContent);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Barcode PDF Generator</h2>

      <input
        type="text"
        placeholder="Enter barcode text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button onClick={handleAddBarcode}>Add More</button>

      <ul>
        {barcodeList.map((item, idx) => (
          <li key={idx}>
            {item} <button onClick={() => handleRemove(idx)}>Remove</button>
          </li>
        ))}
      </ul>

      {barcodeList.length > 0 && (
        <>
          <button style={{ marginTop: 20 }} onClick={handlePreparePDF}>
            Prepare PDF
          </button>

          {pdfDoc && (
            <div style={{ marginTop: 20 }}>
              <PDFDownloadLink document={pdfDoc} fileName="barcodes.pdf">
                {({ loading }) => (loading ? 'Preparing PDF...' : 'Download PDF')}
              </PDFDownloadLink>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BarcodeGenerator;
