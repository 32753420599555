import React, { useEffect, useState } from 'react'
import HomeHero from '../components/HomeHero'
import Training from '../components/Training'
import DialogModel from '../components/DialogModel'
import Webinar from '../components/Webinar';
import ChatWithus from '../components/ChatWithus';
import Companies from '../components/companies/Companies';
import YoutubeVideos from '../components/YoutubeVideos'
import Certificate from '../components/Certificate';
import Feedback from '../components/Feedback';
import Adwantages from '../components/advantages/Adwantages';
import Skills from '../components/Skills';
import QuizModal from '../components/QuizModal';
import EliteProgram from '../components/EliteProgram';
import Elite from '../components/Elite';
import Funfact from '../components/Funfact';
import OurCourses from '../components/ourCourses/OurCourses';
import Course from '../components/courses/Course';
// import Prefer from '../components/ourCourses/Prefer/Prefer';
import Hero from '../components/hero/Hero';
import AllCourses from '../components/allCourses/AllCourses';
import MenteesSection from '../components/menteesSection/MenteesSection';
import { Helmet } from 'react-helmet';
import ScratchCardComponent from '../components/ScratchCardComponent/ScratchCardComponent';

function Home() {
      // Model open logic
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShowModal(true);
  //   }, 1 * 60 * 1000); // 5 minutes

  //   return () => clearInterval(interval);
  // }, []);
  return (
    <section className='home p-0 overflow-hidden'>
       <Helmet>
        <title>Software Training Institute in Bangalore | Software Testing Course</title>
        <meta name="description" content="Looking for Software Training Institute in Bangalore? Visit Be-practical for Software Testing Course & comprehensive training for various IT courses." />
        <meta name="keywords" content="Software Training Institute in Bangalore" />
        <link rel="canonical" href="https://be-practical.com/" />
      </Helmet>
         <DialogModel show={showModal} onHide={handleCloseModal} />
        {/* <HomeHero/> */}
        <div className="hero-gradient">
          <div className="animated-bg"></div>
        <div className="container py-5 ">
        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-lg-6 hero-content">
            <h1 className="display-3 fw-bold text-white mb-4 glow-text">
              CODE CARNAGE
              <span className="d-block text-info mt-2">24HR HACKATHON</span>
            </h1>
            <p className="lead text-white-50 mb-5">
              Unleash your coding potential at SJB Institute of Technology's most intense hackathon event. 
              Transform your ideas into reality and compete with the best minds!
            </p>
            <div className="mb-5">
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-calendar-event text-info me-3 fs-4"></i>
                <span className="text-white">11th April 2025</span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-clock text-info me-3 fs-4"></i>
                <span className="text-white">9:00 AM Onwards</span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-geo-alt text-info me-3 fs-4"></i>
                <span className="text-white">CSE Seminar Hall, SJBIT Campus</span>
              </div>
            </div>
            <button className="btn btn-custom btn-lg px-5 py-3 rounded-pill text-white">
              Register Now
            </button>
          </div>

          {/* Right Content - Features */}
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="glass-card p-4 rounded-4 h-100">
                  <i className="bi bi-code-slash feature-icon mb-3"></i>
                  <h3 className="text-white fs-4 mb-3">Coding Challenges</h3>
                  <p className="text-white-50 mb-0">Tackle real-world problems with innovative solutions and cutting-edge technology</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="glass-card p-4 rounded-4 h-100">
                  <i className="bi bi-trophy feature-icon mb-3"></i>
                  <h3 className="text-white fs-4 mb-3">Amazing Prizes</h3>
                  <p className="text-white-50 mb-0">Win exciting prizes, certificates, and recognition worth ₹50,000</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="glass-card p-4 rounded-4 h-100">
                  <i className="bi bi-people feature-icon mb-3"></i>
                  <h3 className="text-white fs-4 mb-3">Networking</h3>
                  <p className="text-white-50 mb-0">Connect with industry experts, mentors, and like-minded developers</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="glass-card p-4 rounded-4 h-100">
                  <i className="bi bi-mortarboard feature-icon mb-3"></i>
                  <h3 className="text-white fs-4 mb-3">Learning</h3>
                  <p className="text-white-50 mb-0">Enhance your technical skills through workshops and hands-on experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
       
        <Hero/>
        {/* <ScratchCardComponent/> */}
        <Companies/>
       <div className="spacer mt-5">
        {/* <COu/> */}
        <AllCourses/>
       </div>
       <Skills/>
       <Elite/>
       <EliteProgram/>
        <Training/>
        {/* <Companies/> */}
        <Webinar/>
        <QuizModal/>
        <Adwantages/>
        {/* <MenteesSection/> */}
        <YoutubeVideos/>
        <ChatWithus/>
        <Funfact/>
        <Feedback/>
    </section>
  )
}

export default Home
